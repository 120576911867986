const { default: axios } = require("axios");
import { createApp, toHandlers } from 'vue';
import TomSelect from 'tom-select';
import { feedback } from 'laravel-mix/src/Log';
import parseJson from 'parse-json';
import VueRouter from 'vue-router';

var ff = 0;

window.addEventListener('load',function() {

    ff = createApp({
        delimiters: ['${', '}'],
        data() {
            return {
                page: '',
                waitForApi: false,
                catsel: 0,
                // Sheet
                sheetSubmit: false,
                sheets: [],
                fromwp: [],
                coursesfromwp: [],
                coursefromwp: [],
                waitingfromwp: [],
                selectedcourse: false,
                showAlsoDrafts: false,
                imported: false,
                importSearch: '',
                searchString: '',
                sortColumn: false,
                sortOrder: 1,
                showArchive: false,
                showStatus: -1,
                calcfrom: new Date().getFullYear() + '-01-01',
                calcuntil: new Date().getFullYear() + '-12-31',
                calccategory: 0,
                initalSheet: false,
                initialMaterial: false,
                initialCategory: false,
                initialPerson: false,
                initialLocation: false,
                peopleSearch: '',
                showPeopleCat: -1,
                sheet: {
                },
                program: {
                    goals: 'goals',
                    topics: [
                        {
                            id: 0,
                            hours: 0,
                            date: '2000-01-01',
                            topic: 'topic'
                        }
                    ]
                },
                status: ['Entwurf', 'Gebucht', 'Angefragt', 'Archiv'],
                defaultrooms: ['Indian Summer', 'Wintergarten', 'Holzraum', 'Schöne Aussicht', 'Balkenzimmer', 'Steinraum', 'Toskana/BNE-Raum', 'Zelt', 'Speisesaal', 'Sonstiges'],
                checklist: [],
                newchecklist: '',
                editChecklist: false,
                sort20percent: 'from',
                sortOrder20percent: 1,
                sortPeople: 'name',
                sortOrderPeople: 1,
                sortCategories: 'title',
                sortOrderCategories: 1,
                sortLocations: 'name',
                sortOrderLocations: 1,
                feedback: {
                    id: 0,
                    liked: '',
                    disliked: '',
                    takeaway: '',
                    name: '',
                    room: '',
                    email: '',
                    consent: false
                },
                feedback_secret: '',
                rooms: [],
                feedbacks: [],
                speakers: [],
                locations: [
                ],
                location: {
                    id: 0,
                    name: '',
                    address: '',
                    note: ''
                },
                materials: [],
                material: {
                    id: 0,
                    title: '',
                },
                types: [
                    {
                        id: 1,
                        title: 'Krankenkasse'
                    },
                    {
                        id: 2,
                        title: 'Bildungsurlaub'
                    }
                ],
                categories: [
                ],
                category: {
                    id: 0,
                    short: '',
                    title: ''
                },
                people: [
                ],
                peoplecats: [],
                peoplecat: {
                    id: 0,
                    title: '',
                    short: ''
                },
                person: {
                    id: 0,
                    name: '',
                    email: '',
                    phone: '',
                    fax: '',
                    feedback: '',
                    note: ''
                },
                new_user_name: '',
                new_user_email: '',
                new_user_password: '',
                new_user_password_confirm: ''
            }
        },
        methods: {
            /*******************************************/
            /*
            /*  GUI
            */
           uc: function() {
                alert("Noch nicht implementiert.");
                return false;
           },
           initSelects: function() {
                //jQuery('#sheetimport').select2();
           },
           parseHash: function( hash ) {
                if(hash) {
                    var hash = hash.split('/');
                    switch(hash[0]) {
                        case 'sheet':
                            this.initialSheet = hash[1];
                            break;
                        case 'docket':
                            this.initialSheet = hash[1];
                            break;
                        case 'material':
                            this.initialMaterial = hash[1];
                            break;
                        case 'category':
                            this.initialCategory = hash[1];
                            break;
                        case 'person':
                            this.initialPerson = hash[1];
                            break;
                        case 'location':
                            this.initialLocation = hash[1];
                            break;
                        default:
                            this.sp(hash[0]);
                    };
                    if(hash[0]) {
                        this.sp(hash[0]+((this.sheet.id)?'/'+this.sheet.id:''));
                    }
                }

           },
           sp: function( id ) {
                var blockThese = ["sheet","docket","material","category","person","location"];
                this.clearTables();
                var page = document.getElementById(id);
                if(page) {
                    this.page = id;
                    document.querySelectorAll('section').forEach(function(el){
                        el.classList.remove('show');
                    });
                    page.classList.add('show');
                    if(blockThese.indexOf(id) == -1) {
                        self.location.href = '#'+id;
                    };
                    if(this.sheet.id) {
                        if(id == 'sheet') {
                            self.location.href = '#sheet/'+this.sheet.id;
                        } else if(id == 'docket') {
                            self.location.href = '#docket/'+this.sheet.id;
                        }
                        this.initSelects();
                    }
                }
           },
            editChecklistToogle: function() {
                this.editChecklist = !this.editChecklist;
                if(!this.editChecklist) {
                    this.repairChecklist();
                }
                    
            },
            formatDate: function( date ) {
                var d = new Date(date);
                return d.toLocaleDateString("de-DE",{ month: '2-digit', day: '2-digit', year: 'numeric' });
            },
            formatDateIso: function( date ) {
                var d = new Date(date);
                return d.toISOString().substr(0,10);
            },
            /*******************************************/
            /*
            /*  Sheets
            */
            clearSheet( gotoEditor ) {
                this.clearTables();
                this.calcfrom = new Date().getFullYear() + '-01-01';
                this.calcuntil = new Date().getFullYear() + '-12-31';
                this.sheet.id = 0;
                this.sheet.status = 0;
                this.sheet.include_in_calc = 0;
                this.sheet.sheet = false;
                this.sheet.vanr =  '';
                this.sheet.title =  '';
                this.sheet.subtitle =  '';
                this.sheet.notes =  '';
                this.sheet.type_kk = false;
                this.sheet.type_bu = false;
                this.sheet.type_cc = false;
                this.sheet.type_bne = false;
                this.sheet.type_ep = false;
                this.sheet.type_project = false;
                this.sheet.category =  0;
                this.sheet.hpm =  0;
                this.sheet.tutor =  0;
                this.sheet.precondition =  '';
                this.sheet.material =  '';
                this.sheet.groupsize =  '';
                this.sheet.results =  '';
                this.sheet.feedback_hpm =  '';
                this.sheet.datetype =  0; // 0 = period; 1 = dates
                this.sheet.from =  "2000-01-01";
                this.sheet.until =  "2000-01-01";
                this.sheet.deadline =  '2000-01-01';
                this.sheet.location =  0;
                this.sheet.cost_fee =  0;
                this.sheet.cost_travel =  0;
                this.sheet.cost_rent =  0;
                this.sheet.cost_misc =  0;
                this.sheet.event_type =  0;
                this.sheet.cnt_days_planned =  0;
                this.sheet.cnt_days_plus20 =  0;
                this.sheet.cnt_days_plus20_actually =  0;
                this.sheet.cnt_people_planned =  0; // deprecated
                this.sheet.cnt_people_actually =  0; // deprecated
                this.sheet.cnt_days_actually =  0;
                this.sheet.cnt_people_plus20 =  0; // deprecated
                this.sheet.cnt_people_nrw =  0; // deprecated
                this.sheet.calc_people_day =  0; // deprecated
                this.sheet.calc_hours =  0;
                this.sheet.calc_nrw =  0;
                this.sheet.calc_not_nrw =  0;
                this.sheet.cost_per_people =  0;
                this.sheet.time_plan = 0;            
                this.sheet.time_evaluation = 0;            
                this.sheet.time_newtopic = 0;            
                this.sheet.time_newgroup = 0;            
                this.sheet.time_newtutor = 0;            
                this.sheet.time_newconcept = 0;            
                this.sheet.time_digital = 0;            
                this.sheet.time_social = 0;            
                this.sheet.time_misc = 0;            
                this.sheet.time_misc_title = '';            
                this.sheet.cnt_people_actually= 0;
                this.sheet.cnt_people_docket= 0;
                this.sheet.docket_person= 0;
                this.sheet.docket_arrivaldate = '';
                this.sheet.docket_departdate = '';
                this.sheet.docket_departtime = '';
                this.sheet.docket_departmeal = '';
                this.sheet.docket_arrivaltime = '';
                this.sheet.docket_arrivalmeal = '';
                this.sheet.docket_mealcnt = 0;
                this.sheet.docket_person_email = '';
                this.sheet.docket_mealstd = 0;
                this.sheet.docket_mealveget = 0;
                this.sheet.docket_mealvegan = 0;
                this.sheet.docket_mealnopork = 0;
                this.sheet.docket_mealnogluten = 0;
                this.sheet.docket_mealnolactose = 0;
                this.sheet.docket_mealnotes = '';
                this.sheet.docket_mealcoffee = '08:00';
                this.sheet.docket_mealbreakfast = '08:30';
                this.sheet.docket_meallunch = '13:00';
                this.sheet.docket_mealtea = '15:00';
                this.sheet.docket_mealdinner = '18:30';
                this.sheet.docket_mealroom = '';
                this.sheet.docket_mealdrinks = '';
                this.sheet.docket_roomdouble = 0;
                this.sheet.docket_roomsingle = 0;
                this.sheet.docket_roomleave = '10:00';
                this.sheet.docket_roomnote = '';
                this.sheet.docket_classroom = '';
                this.sheet.docket_classroomchairs = '';
                this.sheet.docket_classroommedia = '';
                this.sheet.docket_costperson = 0;
                this.sheet.docket_costwire = 0;
                this.sheet.docket_costsheets = 0;
                this.sheet.docket_costrent = 0;
                this.sheet.docket_rgs = 0;
                this.sheet.docket_costdrinks = 0;
                this.sheet.docket_costcash = 0;
                this.sheet.docket_costcopies = 0;
                this.sheet.docket_costtotal = 0;
                this.sheet.docket_note = '';
                this.checklist = [];
                this.rooms = [];
                this.feedbacks = [];
                this.speakers = [];
                this.newSpeaker = 0;
                this.newSpeakers = [];
                if( gotoEditor ) {
                    this.sp('sheet');
                }
            },
            clearFeedback( ) {
                this.feedback.id = 0;
                this.feedback.name = '';
                this.feedback.email = '';
                this.feedback.room = '';
                this.feedback.liked = '';
                this.feedback.disliked = '';
                this.feedback.takeaway = '';
                this.feedback.wish = '';
                this.feedback.consent = false;
            },
            clearTables() {
                this.sortColumn = false;
                this.sortOrder = 1;
                this.showStatus = -1;
            },
            getBookingsFromWP() {
                this.waitForApi = true;
                axios.get('/getBookingsFromWP').then( function(r) {
                    this.waitForApi = true;
                    this.fromwp = r.data.entries.filter( function( s ) {
                        var alreadyimported = false;
                        this.sheets.forEach( function( s2 ) {
                            if( s2.wpid && s2.wpid == s["id"] ) {
                                alreadyimported = true;
                            }
                        });
                        return !alreadyimported;
                    }.bind(this));
                    this.fromwp.sort(function(a, b) {
                        if (a["title"] < b["title"]) return -1;
                        if (a["title"] > b["title"]) return 1;
                        return 0;
                    });
                }.bind(this)).catch( function() {
                    this.waitForApi = true;
                    alert("Buchungs-Daten aus Wordpress konnten nicht importiert werden.");
                })
            },
            getCoursesFromWP() {
                this.waitForApi = true;
                axios.get('/getCoursesFromWP').then( function(r) {
                    this.waitForApi = false;
                    this.coursesfromwp = r.data.filter( function( s ) {
                        return true;
                    });
                }.bind(this)).catch( function() {
                    this.waitForApi = false;
                    alert("Kurs-Daten aus Wordpress konnten nicht importiert werden.");
                })
            },
            getCourseFromWP() {
                var tempTitle = this.selectedcourse.title.rendered;
                var tempElement = document.createElement('div');
                tempElement.innerHTML = tempTitle;
                var courseTitle = tempElement.innerText;
                this.waitForApi = true;
                this.coursefromwp.loaded = false;
                this.waitingfromwp.loaded = false;
                axios.get('/getCourseFromWP/'+this.selectedcourse.id+"/"+encodeURI(courseTitle)).then( function(r) {
                    this.coursefromwp = r.data.bookings;
                    this.coursefromwp.loaded = true;
                    this.waitingfromwp = r.data.waiting;
                    this.waitingfromwp.loaded = true;
                    axios.get('/courses').then( function(r) {
                        this.waitForApi = false;
                        r.data.forEach(function(e) {
                            if( e.course === this.selectedcourse.id ) {
                                this.coursefromwp.notes = e.notes;
                            }
                            this.coursefromwp.entries.forEach(function(c) {
                                if(e.gfid == c.id) {
                                    c.notes = e.notes;
                                    c.finance = e.finance;
                                }
                            }.bind(this))
                            if(this.waitingfromwp.entries) {
                                this.waitingfromwp.entries.forEach(function(c) {
                                    if(e.gfid == c.id) {
                                        c.notes = e.notes;
                                        c.finance = e.finance;
                                    }
                                }.bind(this))
                            }
                        }.bind(this));
                    }.bind(this)).catch( function() {
                        ff.waitForApi = false;
                        alert("Kurs-Fills konnten nicht importiert werden.");
                    }.bind(this))
                }.bind(this)).catch( function(e) {
                    ff.waitForApi = false;
                    console.log(e);
                    alert("Kurs-Daten aus Wordpress konnten nicht importiert werden.");
                }.bind(this))
            },
            exportSignatureList() {
                axios.post('/exportSignatureList', { 
                    kurs: this.selectedcourse.acf.nummer,
                    list: this.filterCourse 
                }, { responseType: 'blob' })
                .then(function(response) {
                    const blob = new Blob([response.data], { type: 'text/csv' }); 
                    const url = window.URL.createObjectURL(blob); 
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = this.selectedcourse.acf.nummer.replace(' ','-')+'-'+this.selectedcourse.slug+'.csv'; 
                    document.body.appendChild(a);
                    a.click(); 
                    a.remove(); 
                    window.URL.revokeObjectURL(url);
                }.bind(this))
                .catch(function(error) {
                    console.error('Error downloading the file', error);
                });
            },
            selectCourse( idx ) {
                this.selectedcourse = this.filterCourses[idx];
            },
            importFromWP( idx, fromFilter ) {
                if( idx !== undefined ) {
                    if(fromFilter) {
                        this.imported = this.filterImported[idx];
                    } else {
                        this.imported = this.fromwp[idx];
                    }
                }
                this.sheet.title = this.imported["title"];
                this.sheet.wpid = this.imported["id"];
                this.sheet.subtitle = this.imported[15];
                this.sheet.docket_person = this.imported['1.3']+" "+this.imported['1.6']+"\n"+this.imported[2]+"\n"+this.imported["4.1"]+"\n"+this.imported["4.5"]+" "+this.imported["4.3"]
                this.sheet.docket_person_email = this.imported[5]
                this.sheet.docket_person_phone = this.imported[6]
                this.sheet.groupsize = isNaN(this.imported[21])?0:this.imported[21]
                this.sheet.from = this.imported[8]
                this.sheet.until = this.imported[9]
                this.sheet.docket_mealcoffee = this.imported[10]
                this.sheet.docket_arrivaldate = this.imported[8]
                this.sheet.docket_arrivaltime = this.imported[10]
                this.sheet.docket_departdate = this.imported[9]
                this.sheet.docket_departtime = this.imported[11]
                this.sheet.docket_arrivalmeal = this.imported[12]
                this.sheet.docket_departmeal = this.imported[13]
                this.sheet.docket_note = this.imported[16]
            },
            getSheets( force ) {
                if(this.waitForApi && !force) {
                    return;
                }
                this.waitForApi = true;
                axios.get('/sheets').then(
                    function(r) {
                        this.sheets = r.data;
                        this.waitForApi = false;
                        if(this.initialSheet!==false) {
                            this.getSheet(this.initialSheet, (['sheet','docket'].indexOf(this.page)==-1)?true:this.page, true);
                            this.initialSheet = false;
                        }
                    }.bind(this)
                ).catch( function(e) {
                    this.waitForApi = false;
                    alert("Error loading sheets.");
                }.bind(this))
            },
            getSheet(id, onSuccessGoTo, force, simple) {
                if(!id) {
                    return;
                }
                if(!force && this.waitForApi) {
                    return;
                }
                this.waitForApi = true;
                axios.get(
                    '/sheets/'+id,
                    { 
                        id: id,
                        simple: simple,
                    }
                ).then( function(r) {
                    this.sheet = r.data.sheet;
                    this.checklist = r.data.checklist;
                    this.feedbacks = r.data.feedbacks;
                    this.speakers = r.data.speakers;
                    this.rooms = r.data.rooms;
                    this.rooms.forEach( function(r) {
                        if(r.materials) {
                            r.materials = parseJson(r.materials);
                        } else {
                            r.materials = {};
                        }
                    });
                    this.sheet.type_kk = (this.sheet.type_kk==1)?true:false;
                    this.sheet.type_bu = (this.sheet.type_bu==1)?true:false;
                    this.sheet.type_cc = (this.sheet.type_cc==1)?true:false;
                    this.sheet.type_bne = (this.sheet.type_bne==1)?true:false;
                    this.sheet.type_ep = (this.sheet.type_ep==1)?true:false;
                    this.sheet.type_project = (this.sheet.type_project==1)?true:false;
                    this.sheet.include_in_calc = (this.sheet.include_in_calc==undefined)?0:this.sheet.include_in_calc;
                    this.repairChecklist();
                    this.waitForApi = false;
                    if( onSuccessGoTo ) {
                        this.sp( onSuccessGoTo?onSuccessGoTo:(this.sheet.sheet?'sheet':'docket') );
                    } else {
                        this.sp( onSuccessGoTo?onSuccessGoTo:(this.sheet.sheet?'sheet':'docket') );
                    }
                    self.location.hash = '#ff'+onSuccessGoTo?onSuccessGoTo+'/'.this.sheet+id:(this.sheet.sheet?'sheet':'docket')+'/'+this.sheet.id;
                }.bind(this)).catch(function() {
                    this.waitForApi = false;
                }.bind(this))
            },
            storeSheet( onSuccessGoTo ) {
                window.setTimeout( function() {
                    this.doStoreSheet( onSuccessGoTo );
                }.bind(this), 200);
            },
            doStoreSheet( onSuccessGoTo ) {
                if(this.waitForApi) {
                    return;
                }
                this.waitForApi = true;
                this.sheetSubmit = true;
                if(!this.sheetValid) {
                    window.setTimeout( function() {
                        this.waitForApi = false;
                        alert("Bitte Eingaben überprüfen.");
                        document.querySelector('#sheet .error').scrollIntoView({ behavior : 'smooth'});
                    }.bind(this),200);
                    return;
                }
                if(this.sheet.id) {
                    if(this.newchecklist) {
                        this.checklist.push({
                            sheet: this.sheet.id,
                            sort: 0,
                            checked: false,
                            topic: this.newchecklist
                        });
                        this.newchecklist = '';
                    }
                    this.sheet.cost_fee = this.feeCalc;
                    axios.put('sheets/'+this.sheet.id,
                    {
                        sheet: this.sheet,
                        checklist: this.checklist,
                        speakers: this.newSpeaker?this.speakers.concat({person: this.newSpeaker.id, cost: 0}):this.speakers,
                        rooms: this.rooms.map(function(r) {
                            r.materials = JSON.stringify(r.materials);
                            return r;
                        })
                    }
                    ).then( function(r) {
                        this.sheetSubmit = false;
                        this.getSheets(true);
                        if(this.sheet.sheet) {
                            this.getSheet(r.data.id,"sheet",true);
                        } else {
                            this.getSheet(r.data.id,"docket",true);
                        }
                    }.bind(this)).catch(function(e) {
                        console.log(e);
                        this.waitForApi = false;
                        alert("Error saving.");
                    }.bind(this))
                } else {
                    axios.post('sheets',
                    {
                        sheet: this.sheet,
                        checklist: this.checklist,
                        rooms: this.rooms.map(function(r) {
                            r.materials = JSON.stringify(r.materials);
                            return r;
                        })
                    }
                    ).then( function(r) {
                        this.getSheets(true);
                        this.sheetSubmit = false;
                        if(this.sheet.sheet) {
                            this.getSheet(r.data.id,"sheet",true);
                        } else {
                            this.getSheet(r.data.id,"docket",true);
                        }
                    }.bind(this)).catch(function(e) {
                        this.waitForApi = false;
                        alert("Error saving.");
                    }.bind(this))
                }
            },
            deleteSheet() {
                if(this.waitForApi) {
                    return;
                }
                if(confirm('"'+this.sheet.title+'" wirklich löschen? Dies kann nicht rückgängig gemacht werden!')) {
                    axios.delete('sheets/'+this.sheet.id,
                    {
                        id: this.sheet.id,
                    }
                    ).then( function(r) {
                        this.getSheets(true);
                        this.sp('sheets');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error deleting.");
                    })
                }
            },
            setSort( id, sort ) {
                if(id!=this.sortColumn) {
                    this.sortColumn = id;
                    this.sortOrder = 1;
                } else {
                    this.sortOrder = -1 * this.sortOrder;
                }
            },
            setSortColumn20Percent: function(idx) {
                if(this.sort20percent==idx) {
                    this.sortOrder20percent = this.sortOrder20percent * -1;
                } else {
                    this.sort20percent = idx;
                    this.sortOrder20percent = 1;
                }
            },
            addRoom: function() {
                this.rooms.push({ name: '', classroom: '', classroomchairs: '', classroommedia: '', materials: {} });
                window.setTimeout(function() {
                    let el = document.querySelector('.room:last-child');
                    el.scrollIntoView();
                    let inp = document.querySelector('.room:last-child input');
                    inp.focus();
                },100);
            },
            deleteRoom: function(name, idx) {
                if(confirm(name+idx+" wirklich löschen? Dies kann nicht rückgängig gemacht werden.")) {
                    this.rooms.splice(idx,1);
                }
            },
            checkIncludeInCalc: function() {
                if(!this.cnt_days_planned_is_numeric || !this.sheet.include_in_calc) {
                    return;
                }
            },
            setPlus20Planned: function() {
                this.sheet.cnt_days_plus20 = this.sheet.cnt_days_planned*1.2;
            },
            setPlus20Actually: function() {
                this.sheet.cnt_days_plus20_actually = this.sheet.cnt_days_actually*1.2;
            },
            submitFeedback: function() {
                if(!this.feedback.consent) {
                    alert("Bitte Datenschutzbestimmungen akzeptieren.");
                    return false;
                }
                document.querySelector('form').submit();
            },
            storeCourse( ) {
                if(this.waitForApi) {
                    return;
                }
                this.courseSubmit = true;
                var data = [];
                this.filterCourse.forEach(function(c) {
                    data.push({gfid: c.id, finance: c.finance==true, notes: c.notes});
                })
                this.filterWaits.forEach(function(c) {
                    data.push({gfid: c.id, finance: c.finance==true, notes: c.notes});
                })
                this.waitForApi = true;
                axios.post('courses',{ entries: data, courseId: this.selectedcourse.id, notes: this.coursefromwp.notes }).then( function(r) {
                    this.waitForApi = false;
                }.bind(this)).catch(function(e) {
                    this.waitForApi = false;
                    alert("Error saving.");
                }.bind(this))
            },
            /*******************************************/
            /*
            /*  Kategorien
            */
            getCategories( force ) {
                if(this.waitForApi && !force) {
                    return;
                }
                this.waitForApi = true;
                axios.get('/categories').then(
                    function(r) {
                        this.categories = r.data;
                        this.waitForApi = false;
                    }.bind(this)
                ).catch( function(e) {
                    this.waitForApi = false;
                }.bind(this))
            },
            editCategory( cat, ignoreHash = false) {
                if(!isNaN(cat)) {
                    cat = this.categories.find( function(m) {
                        return m.id == cat;
                    });
                }
                this.category.id = cat.id;
                this.category.short = cat.short;
                this.category.title = cat.title;
                this.sp('category');
                if(!ignoreHash) {
                    self.location.hash = '#category/' + cat.id;
                }
            },
            clearCategory( gotoEditor ) {
                this.category.id = 0;
                this.category.short = '';
                this.category.title = '';
                if(gotoEditor) {
                    this.sp('category');
                }
            },
            storeCategory() {
                if(this.waitForApi) {
                    return;
                }
                //this.waitForApi = true;
                if(this.category.id) {
                    axios.put('categories/'+this.category.id,
                    {
                        category: this.category,
                    }
                    ).then( function(r) {
                        this.getCategories(true);
                        this.sp('categories');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error saving category.");
                    })
                } else {
                    axios.post('categories',
                    {
                        category: this.category,
                    }
                    ).then( function(r) {
                        this.getCategories(true);
                        this.sp('categories');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error creating category.");
                    })
                }
            },
            deleteCategory: function() {
                if(this.waitForApi) {
                    return;
                }
                if(confirm('"'+this.category.title+'" wirklich löschen? Dies kann nicht rückgängig gemacht werden!')) {
                    axios.delete('categories/'+this.category.id,
                    {
                        id: this.category.id,
                    }
                    ).then( function(r) {
                        this.getCategories(true);
                        this.sp('categories');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error deleting.");
                    })
                }
            },
            setSortColumnCategories: function(idx) {
                if(this.sortCategories==idx) {
                    this.sortOrderCategories = this.sortOrderCategories * -1;
                } else {
                    this.sortCategories = idx;
                    this.sortOrderCategories = 1;
                }
            },
            /*******************************************/
            /*
            /*  People
            */
            getPeople( force ) {
                if(this.waitForApi && !force) {
                    return;
                }
                this.waitForApi = true;
                axios.get('/peoples').then(
                    function(r) {
                        this.people = r.data;
                        this.waitForApi = false;
                        if(this.initialPerson!=false) {
                            this.editPerson(this.initialPerson, true);
                            this.initialPerson = false;
                        }
                    }.bind(this)
                ).catch( function(e) {
                    this.waitForApi = false;
                    alert("Error loading people.");
                }.bind(this))
            },
            editPerson( person, ignoreHash ) {
                if(!isNaN(person)) {
                    person = this.people.find( function(m) {
                        return m.id == person;
                    });
                }
                this.person =  Object.assign(person);
                if(!ignoreHash) {
                    self.location.hash = '#person/' + person.id;
                }
                this.sp('person');
            },
            clearPerson( gotoEditor ) {
                this.person.id = 0;
                this.person.name = '';
                this.person.email = '';
                this.person.phone = '';
                this.person.fax = '';
                this.person.note = '';
                this.person.feedback = '';
                if(gotoEditor) {
                    this.sp('person');
                }
            },
            storePerson() {
                if(this.waitForApi) {
                    return;
                }
                this.waitForApi = true;
                if(this.person.id) {
                    axios.put('peoples/'+this.person.id,
                    {
                        person: this.person,
                    }
                    ).then( function(r) {
                        this.getPeople(true);
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error saving person.");
                    })
                } else {
                    axios.post('peoples',
                    {
                        person: this.person,
                    }
                    ).then( function(r) {
                        this.getPeople(true);
                        this.sp('people');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error creating person.");
                    })
                }
            },
            deletePerson: function() {
                if(this.waitForApi) {
                    return;
                }
                if(confirm('"'+this.person.name+'" wirklich löschen? Dies kann nicht rückgängig gemacht werden!')) {
                    axios.delete('peoples/'+this.person.id,
                    {
                        id: this.person.id,
                    }
                    ).then( function(r) {
                        this.getPeople(true);
                        this.sp('people');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error deleting.");
                    })
                }

            },
            deleteFile: function( name, url ) {
                if(this.waitForApi) {
                    return;
                }
                if(confirm('Datei wirklich löschen? Dies kann nicht rückgängig gemacht werden!')) {
                    axios.post('deleteFile',
                    {
                        file: url  
                    }
                    ).then( function(r) {
                        this.person.files = this.person.files.filter( function(f) {
                            return f != name;
                        });
                        this.person.fileurls = this.person.fileurls.filter( function(f) {
                            return f.name != name;
                        });
                        this.storePerson();
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error deleting.");
                    }.bind(this));
                }
            },
            getPerson( id ) {
                var returnPerson = false;
                this.people.forEach( function(p) {
                    if(p.id == id) {
                        returnPerson = p;
                    }
                });
                return returnPerson;
            },
            addSpeaker() {
                if(this.newSpeaker) {
                    this.speakers.push({
                        cost: 0,
                        person: this.newSpeaker.id,
                        sheet: this.sheets[0].id,
                    });
                    this.newSpeaker = 0;
                    this.$forceUpdate();

                        
                }
            },
            removeSpeaker( id ) {
                var s = this.speakers.filter( function(s) {
                    return s.person != id;
                });
                this.speakers = s;
                this.newSpeaker = 0;
                this.$forceUpdate();

            },
            isImported( person ) {
                var cat_imp = this.peoplecats.find( function(c) {
                    return c.short == 'IMP';
                });
                return cat_imp && person.cat == cat_imp.id;
            },
            setSortColumnPeople: function(idx) {
                if(this.sortPeople==idx) {
                    this.sortOrderPeople = this.sortOrderPeople * -1;
                } else {
                    this.sortPeople = idx;
                    this.sortOrderPeople = 1;
                }
            },
            /*******************************************/
            /*
            /*  People Kategorien
            */
            getPeopleCategories( force ) {
                if(this.waitForApi && !force) {
                    return;
                }
                this.waitForApi = true;
                axios.get('/peoplecats').then(
                    function(r) {
                        this.peoplecats = r.data;
                        this.waitForApi = false;
                    }.bind(this)
                ).catch( function(e) {
                    this.waitForApi = false;
                }.bind(this))
            },
            editPeopleCategory( peoplecat, ignoreHash = false) {
                if(!isNaN(peoplecat)) {
                    peoplecat = this.peoplecats.find( function(m) {
                        return m.id == cat;
                    });
                }
                this.peoplecat.id = peoplecat.id;
                this.peoplecat.short = peoplecat.short;
                this.peoplecat.title = peoplecat.title;
                this.sp('peoplecat');
                if(!ignoreHash) {
                    self.location.hash = '#peoplecat/' + cat.id;
                }
            },
            clearPeopleCategory( gotoEditor ) {
                this.peoplecat.id = 0;
                this.peoplecat.short = '';
                this.peoplecat.title = '';
                if(gotoEditor) {
                    this.sp('peoplecat');
                }
            },
            storePeopleCategory() {
                if(this.waitForApi) {
                    return;
                }
                //this.waitForApi = true;
                if(this.peoplecat.id) {
                    axios.put('peoplecats/'+this.peoplecat.id,
                    {
                        peoplecat: this.peoplecat,
                    }
                    ).then( function(r) {
                        this.getPeopleCategories(true);
                        this.sp('peoplecats');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error saving peoplecat.");
                    })
                } else {
                    axios.post('peoplecats',
                    {
                        peoplecat: this.peoplecat,
                    }
                    ).then( function(r) {
                        this.getPeopleCategories(true);
                        this.sp('peoplecats');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error creating peoplecat.");
                    })
                }
            },
            deletePeopleCategory: function() {
                if(this.waitForApi) {
                    return;
                }
                if(confirm('"'+this.peoplecat.title+'" wirklich löschen? Dies kann nicht rückgängig gemacht werden!')) {
                    axios.delete('peoplecats/'+this.peoplecat.id,
                    {
                        id: this.peoplecat.id,
                    }
                    ).then( function(r) {
                        this.getPeopleCategories(true);
                        this.sp('peoplecats');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error deleting.");
                    })
                }
            },
            /*******************************************/
            /*
            /*  Locations
            */
            getLocations( force ) {
                if(this.waitForApi && !force) {
                    return;
                }
                this.waitForApi = true;
                axios.get('/locations').then(
                    function(r) {
                        this.locations = r.data;
                        this.waitForApi = false;
                        if(this.initialLocation!==false) {
                            this.editLocation(this.initialLocation);
                            this.initialLocation = false;
                        }
                    }.bind(this)
                ).catch( function(e) {
                    this.waitForApi = false;
                    alert("Error loading locations.");
                }.bind(this))
            },
            editLocation( location, ignoreHash = false ) {
                this.location =  Object.assign(location);
                if(!ignoreHash) {
                    self.location.hash = '#location/' + location.id;
                }
                this.sp('location');
            },
            clearLocation( gotoEditor ) {
                this.location.id = 0;
                this.location.name = '';
                this.location.address = '';
                this.location.note = '';
                if(gotoEditor) {
                    this.sp('location');
                }
            },
            storeLocation() {
                if(this.waitForApi) {
                    return;
                }
                this.waitForApi = true;
                if(this.location.id) {
                    axios.put('locations/'+this.location.id,
                    {
                        location: this.location,
                    }
                    ).then( function(r) {
                        this.getLocations(true);
                        this.sp('locations');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error saving location.");
                    })
                } else {
                    axios.post('locations',
                    {
                        location: this.location,
                    }
                    ).then( function(r) {
                        this.getLocations(true);
                        this.sp('locations');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error creating person.");
                    })
                }
            },
            deleteLocation: function() {
                if(this.waitForApi) {
                    return;
                }
                if(confirm('"'+this.location.name+'" wirklich löschen? Dies kann nicht rückgängig gemacht werden!')) {
                    axios.delete('locations/'+this.location.id,
                    {
                        id: this.location.id,
                    }
                    ).then( function(r) {
                        this.getLocations(true);
                        this.sp('locations');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error deleting.");
                    })
                }

            },
            setSortLocations: function(idx) {
                if(this.sortLocations==idx) {
                    this.sortOrderLocations = this.sortOrderLocations * -1;
                } else {
                    this.sortLocations = idx;
                    this.sortOrderLocations = 1;
                }
            },                
            /*******************************************/
            /*
            /*  Materials
            */
            getMaterials( force ) {
                if(this.waitForApi && !force) {
                    return;
                }
                this.waitForApi = true;
                axios.get('/materials').then(
                    function(r) {
                        this.materials = r.data;
                        this.waitForApi = false;
                        if(this.initialMaterial!==false) {
                            this.editMaterial(this.initialMaterial, true);
                            this.initialMaterial = false;
                        }
                    }.bind(this)
                ).catch( function(e) {
                    this.waitForApi = false;
                    alert("Error loading materials.");
                }.bind(this))
            },
            editMaterial( material, ignoreHash = false ) {
                if(!isNaN(material)) {
                    material = this.materials.find( function(m) {
                        return m.id == material;
                    });
                }
                this.material =  Object.assign(material);
                if(!ignoreHash) {
                    self.location.hash = '#material/' + material.id;
                }
                this.sp('material');
            },
            clearMaterial( gotoEditor ) {
                this.material.id = 0;
                this.material.title = '';
                if(gotoEditor) {
                    this.sp('material');
                }
            },
            storeMaterial() {
                if(this.waitForApi) {
                    return;
                }
                this.waitForApi = true;
                if(this.material.id) {
                    axios.put('materials/'+this.material.id,
                    {
                        material: this.material,
                    }
                    ).then( function(r) {
                        this.getMaterials(true);
                        this.sp('materials');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error saving material.");
                    })
                } else {
                    axios.post('materials',
                    {
                        material: this.material,
                    }
                    ).then( function(r) {
                        this.getMaterials(true);
                        this.sp('materials');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error creating material.");
                    })
                }
            },
            deleteMaterial: function() {
                if(this.waitForApi) {
                    return;
                }
                if(confirm('"'+this.material.title+'" wirklich löschen? Dies kann nicht rückgängig gemacht werden!')) {
                    axios.delete('materials/'+this.material.id,
                    {
                        id: this.material.id,
                    }
                    ).then( function(r) {
                        this.getMaterials(true);
                        this.sp('materials');
                    }.bind(this)).catch(function() {
                        this.waitForApi = false;
                        alert("Error deleting.");
                    })
                }

            },
            /*******************************************/
            /*
            /*  Users
            */
            storeUser() {
                if(this.waitForApi || this.new_user_password!=this.new_user_password_confirm) {
                    return;
                }
                this.waitForApi = true;
                axios.post('/register',
                {
                    name: this.new_user_name,
                    email: this.new_user_email,
                    password: this.new_user_password
                }
                ).then( function(r) {
                    this.waitForApi = false;
                    this.sp('users');
                }.bind(this)).catch(function() {
                    this.waitForApi = false;
                    alert("Error saving user.");
                })
            },
            /*******************************************/
            /*
            /*  Helper
            */
            repairChecklist: function() {
                this.checklist.forEach(function(cl){
                    cl.checked = cl.checked!=0;
                });
                var newChecklist = [];
                this.checklist.forEach(function(cl){
                    if(cl.topic!="") {
                        newChecklist.push(cl);
                    }
                });
                this.checklist = newChecklist;
            }
        },
        computed: {
            filterImported() {
                return this.fromwp.filter( function( s ) {
                    return this.importSearch.trim()=='' || s.title.trim().toUpperCase().indexOf(this.importSearch.trim().toUpperCase()) > -1;
                }.bind(this));
            },
            filterCourses() {
                if(this.coursesfromwp.length==0) {
                    return [];
                }
                return this.coursesfromwp.filter( function( s ) {
                    return (s.status =='publish' || this.showAlsoDrafts) && (this.importSearch.trim()=='' || s.title.rendered.trim().toUpperCase().indexOf(this.importSearch.trim().toUpperCase()) > -1 || s.acf.nummer.trim().toUpperCase().indexOf(this.importSearch.trim().toUpperCase()) > -1);
                }.bind(this));
            },
            filterCourse() {
                if(!this.coursefromwp.loaded) {
                    return false;
                }
                if(this.coursefromwp.entries.length==0) {
                    return [];
                }
                return this.coursefromwp.entries.filter( function( s ) {
                    return this.selectedcourse && this.selectedcourse.id == s[23];
                }.bind(this));
            },
            filterWaits() {
                if(!this.waitingfromwp.loaded) {
                    return false;
                }
                if(!this.waitingfromwp.entries) {
                    return [];
                }
                if(this.waitingfromwp.entries.length==0) {
                    return [];
                }
                var entriesArray = Array.isArray(this.waitingfromwp.entries) ? this.waitingfromwp.entries : Object.values(this.waitingfromwp.entries);
                this.waitingfromwp.entries = entriesArray;
                return this.waitingfromwp.entries.filter( function( s ) {
                    return this.selectedcourse;
                }.bind(this));
            },
            displayCategories: function() {
                return this.categories.sort(function(a, b) {
                    if (a[this.sortCategories] < b[this.sortCategories]) return -1 * this.sortOrderCategories;
                    if (a[this.sortCategories] > b[this.sortCategories]) return 1 * this.sortOrderCategories;
                }.bind(this));
            },
            displaysheets: function() {
                let sheetstmp = this.sheets.filter( function(s) {
                    return ((s.sheet && this.page=='sheets') || (!s.sheet && this.page=='dockets')) && (this.showStatus==-1 || s.status == this.showStatus) && (s.title.toUpperCase().indexOf(this.searchString.toUpperCase())>-1 || (s.subtitle && s.subtitle.toUpperCase().indexOf(this.searchString.toUpperCase())>-1) || (s.vanr && s.vanr.toUpperCase().indexOf(this.searchString.toUpperCase())>-1) || (s.id && s.id == this.searchString));
                }.bind(this));// 
                if(this.sortColumn) {
                    sheetstmp.sort(function(a, b) {
                        if (a[this.sortColumn] < b[this.sortColumn]) return -this.sortOrder;
                        if (a[this.sortColumn] > b[this.sortColumn]) return this.sortOrder;
                        return 0;
                    }.bind(this));
                }
                return sheetstmp;
            },
            displaySheets20percent: function() {
                return this.sheets.filter( function(s) {
                    return (this.calccategory==0 || this.calccategory.indexOf(s.category)>=0) &&  (this.calcfrom=='' || s.from>=this.calcfrom) && (this.calcuntil=='' || s.from<=this.calcuntil);
                }.bind(this)).sort(function(a, b) {
                    if (a[this.sort20percent] < b[this.sort20percent]) return -1*this.sortOrder20percent;
                    if (a[this.sort20percent] > b[this.sort20percent]) return 1*this.sortOrder20percent;
                    return 0;
                }.bind(this));
            },
            sheetValid: function() {
                var valid = true;
                this.speakers.forEach(function(s) {
                    if(s.cost!='' && isNaN(s.cost)) {
                        valid = false;
                    }
                });
                var numbers = ['docket_roomdouble','docket_roomsingle','cnt_days_planned','cnt_people_planned','cnt_days_plus20','cnt_days_actually','cnt_people_actually','cnt_days_plus20_actually','docket_costwire','docket_costcash','docket_costperson','docket_costsheets','docket_costdrinks','docket_costcopies','docket_costtotal','docket_costrent','cost_fee','cost_travel','cost_rent','cost_misc'];
                numbers.forEach(function(n) {
                    if(this.sheet[n]!='' && (isNaN(this.sheet[n]) && isNaN(this.sheet[n].replace(',','.')))) {
                        valid = false;
                    }
                }.bind(this));
                return valid && this.sheet.title!="" && this.sheet.title?.length<=100 && (!this.sheet.subtitle || this.sheet.subtitle?.length<=100) && (this.sheet.category>0 || !this.sheet.sheet);
            },
            cnt_days_actually_is_numeric: function() {
                return this.sheet.cnt_days_actually!='' && !isNaN(this.sheet.cnt_days_actually) && this.sheet.cnt_days_actually*1.2 !=this.cnt_days_plus20;
            },
            cnt_days_planned_is_numeric: function() {
                return this.sheet.cnt_days_planned!='' && !isNaN(this.sheet.cnt_days_planned);
            },
            isTouch: function() {
                return supportsTouch;
            },
            displayPeople: function() {
                return this.people.filter( function(p) {
                    let upperSearch = this.peopleSearch.trim().toUpperCase();

                    return (
                    // If search is empty or if any of the properties matches the search
                    upperSearch === '' ||
                    p.name.trim().toUpperCase().indexOf(upperSearch) > -1 ||
                    (p.email && typeof p.email === 'string' && p.email.trim().toUpperCase().indexOf(upperSearch) > -1) ||
                    (p.note && typeof p.note === 'string' && p.note.trim().toUpperCase().indexOf(upperSearch) > -1) ||
                    (p.importnotes && typeof p.importnotes === 'string' && p.importnotes.trim().toUpperCase().indexOf(upperSearch) > -1) ||
                    (p.feedback && typeof p.feedback === 'string' && p.feedback.trim().toUpperCase().indexOf(upperSearch) > -1)
                    ) &&
                    // Additional condition about category
                    (this.showPeopleCat === -1 || p.cat == this.showPeopleCat);                
                }.bind(this)).sort(function(a, b) {
                    if (a[this.sortPeople] < b[this.sortPeople]) return -1*this.sortOrderPeople;
                    if (a[this.sortPeople] > b[this.sortPeople]) return 1*this.sortOrderPeople;
                    return 0;
                }.bind(this));
            },
            getHPM: function() {
                return this.people.filter( function(p) {
                    var isHPM = false;
                    this.peoplecats.forEach(function(c){
                        if(c.short.toUpperCase()=='HPM' && c.id==p.cat) {
                            isHPM = true;
                        }
                    }.bind(this));
                    return isHPM;
                }.bind(this));
            },
            getRefs: function() {
                return this.people.filter( function(p) {
                    var isREF= false;
                    this.peoplecats.forEach(function(c){
                        if(c.short.toUpperCase()=='REF' && c.id==p.cat) {
                            isREF = true;
                        }
                    }.bind(this));
                    return isREF;
                }.bind(this));
            },
            displayLocations: function() {
                return this.locations.sort(function(a, b) {
                    if (a[this.sortLocations] < b[this.sortLocations]) return -1*this.sortOrderLocations;
                    if (a[this.sortLocations] > b[this.sortLocations]) return 1*this.sortOrderLocations;
                    return 0;
                }.bind(this));
            },
            feeCalc: function() {
                if(this.speakers.length) {
                    let cost = 0;
                    this.speakers.forEach(function(s) {
                        cost += s.cost;
                    });
                    return cost;
                } else {
                    return this.sheet.cost_fee;
                }
            }
        },
        filters: {
            dates: function(value) {
                value = value.toString()
                return value.substr(8,2)+'.'+value.substr(5,2)+'.'+value.substr(0,4);
            }
        },
        init: function() {
            this.clearSheet();
        },
        beforeMount: function() {
            this.clearSheet();
        },
        updated: function() {
            if(this.catsel) {
                this.catsel.destroy();
            }
            this.initSelects();
        },
        mounted: function() {
            this.clearSheet();
            this.getBookingsFromWP();
            this.getCoursesFromWP();
            this.getCategories(true);
            this.getSheets(true);
            this.getPeople(true);
            this.getPeopleCategories(true);
            this.getLocations(true);
            this.getMaterials(true);
            if(document.querySelector('#app').dataset.s) {
                this.getSheet(document.querySelector('#app').dataset.s,'feedback',true);
                this.feedback_secret = document.querySelector('#app').dataset.s;
            }
            if(self.location.hash) {
                this.parseHash(self.location.hash.substr(1));
            } else {
                this.sp('sheets');
            }
            this.initSelects();
            window.addEventListener('hashchange', function( e ) {
                this.parseHash(self.location.hash.substr(1));
            }.bind(this));
        }
    }).mount('#app');

})
